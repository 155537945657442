<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="查看视频"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <cell-group  >
            <cell title="名称："  >        
                {{ video.name }}       
            </cell>
            <cell title="时间："  >        
                {{ video.meetingDate }}       
            </cell>
        </cell-group>
        <video controls="controls"  width='100%;' height="381px" padding-top='-6px' id="videoInfo" :src="video.url">
            <source type="video/*"/>
        </video>
        <RLFooter/>
    </div>
</template>

<script>

    import {getMettingVideo} from '@/api/user';
    import {Cell,CellGroup } from 'vant';
    export default {
        name: 'mettingvideoInfo',
        components: {
            Cell,CellGroup
        },
        data(){
            return {
                video:{
                    name:'',
                    meetingDate:''
                }
            };
        },
        computed:{

        },
        methods:{
            
        }
        ,
        created() {
            getMettingVideo({id:this.$route.query.id}).then(res=>{
                if(res.code==200){
                    console.info('res.data->',res.data);
                    //this.url=res.data.url;
                    this.video=res.data;
                     console.log(this.url)
                }
            });
        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
